<template>
  <div id="Page-User-Forget" class="w720">
		<template v-if="step==1">
			<!-- <div class="title-h2">RESET PASSWORD</div> -->
			<div class="title-h1">{{L("找回密码")}}</div>
			<div class="mt-20"></div>
			<div class="mt-20"></div>
			<div class="mt-10"></div>
			<div class="info">
				{{L("你的密码丢了？请输入您的电子邮件地址。")}}<br/>
				{{L("您将通过电子邮件收到创建新密码的验证码。")}}
			</div>
		</template>
		<template v-if="step==2">
			<!-- <div class="title-h2">PASSWORD RESET</div> -->
			<div class="title-h1">{{L("密码重置")}}</div>
		</template>
		
		<div class="mt-10"></div>
		<div class="mt-20"></div>
		<div class="form-box forget">
			<template v-if="step==1">
				<div class="form-item e-mail" :class="{error:!formRule.email}">
					<div class="label">{{L("电子邮箱")}}<span>*</span></div>
					<div class="input"><input placeholder="E-Mail" type="text" v-model="formData.email" @change="checkEmail" ref="email" name="username" autocomplete="off"/></div>
				</div>
				<div class="form-item verification-code" :class="{error:!formRule.verificationCode}">
					<div class="label">{{L("验证码")}}<span>*</span></div>
					<div class="input">
						<input placeholder="Verification Code" type="text" v-model="formData.verificationCode" @change="checkCode" @keyup.enter="doSubmit(1)" ref="verificationCode" name="verificationCode" maxlength="4" autocomplete="off"/>
						<VerificationCode ref="VerificationCode" type="FRONT_DESK_USER_RETRIEVE_PASSWORD"/>
					</div>
				</div>
				
			</template>
			
			<template v-if="step==2">
				<div class="form-item code" :class="{error:!formRule.code}">
					<div class="label">{{L("验证码")}}<span>*</span></div>
					<div class="input"><input placeholder="Verification Code" type="text" v-model="formData.code" @change="checkEmpty('code')" ref="code" name="code"/></div>
				</div>
				<div class="form-item password" :class="{error:!formRule.password}">
					<div class="label">{{L("新密码")}}<span>*</span><span class="form-tips" v-if="!formRule.password">（{{L("密码必须至少6位，并包含英文字母")}}）</span></div>
					<div class="input"><input placeholder="Password" :type="passwordHide?'password':'text'" v-model="formData.password" @change="checkPassword" ref="password" name="password" autocomplete='new-password'/><PasswordHide @click.native="toggleEye('passwordHide')" :hide="passwordHide"  /></div>
				</div>
				<div class="form-item repassword" :class="{error:!formRule.repassword}">
					<div class="label">{{L("新密码确认")}}<span>*</span><span class="form-tips" v-if="formData.password && !formRule.repassword">（{{L("两次输入密码不一致")}}）</span></div>
					<div class="input"><input placeholder="Password" :type="repasswordHide?'password':'text'" v-model="formData.repassword" @change="checkPasswordConfirm" @keyup.enter="doSubmit(2)" ref="repassword" name="repassword" autocomplete='new-password'/><PasswordHide @click.native="toggleEye('repasswordHide')" :hide="repasswordHide"  /></div>
				</div>
			</template>
			<div class="form-tips">
				<p>{{L("*号为必填项")}}</p>
			</div>
			
			<div class="form-btn">
				<div class="flex between">
					<template v-if="step==1">
					<div><span class="button dark" :class="{loading}" @click="doSubmit(1)">{{L("密码重置")}}</span></div>
					</template>
					<template v-if="step==2">
					<div><span class="button dark" :class="{loading}" @click="doSubmit(2)">{{L("确认登录")}}</span></div>
					</template>
				</div>
			</div>
		</div>
  </div>
</template>

<script>
import VerificationCode from '/src/components/VerificationCode.vue';
import PasswordHide from '/src/components/PasswordHide.vue';
export default {
  name: 'Page-User-Forget',
  components: {
    VerificationCode,
		PasswordHide
  },
  data(){
    return {
			step:1,
			formRule:{
				email:true,
				code:true,
				password:true,
				repassword:true,
				verificationCode:true,
			},
			formData:{
				email:"",
				code:"",
				password:"",
				repassword:"",
				verificationCode:""
			},
			passwordHide:true,
			repasswordHide:true,
			loading:false
    };
  },
  async mounted () {
	
  },
  methods: {
		toggleEye(target){
			this[target] = !this[target];
		},
		checkEmpty(target){
			if(!this.formData[target]){
				this.formRule[target] = false;
				this.$refs[target].focus();
				return false;
			}
			this.formRule[target] = true;
			return true
		},
		checkEmail(){
			if(!this.$root.checkEmail(this.formData.email)){
				this.formRule.email = false;
				this.$refs.email.focus();
				return false;
			}
			this.formRule.email = true;
			return true;
		},
		checkPassword(){
			if(!this.$root.checkPassword(this.formData.password)){
				this.formRule.password = false;
				this.$refs.password.focus();
				return false;
			}
			this.formRule.password = true;
			return true;
		},
		checkPasswordConfirm(){
			if(!this.$root.checkPasswordConfirm(this.formData.password,this.formData.repassword)){
				this.formRule.repassword = false;
				this.$refs.repassword.focus();
				return false;
			}
			this.formRule.repassword = true;
			return true;
		},
		checkCode(){
			if(!this.$root.checkCode(this.formData.verificationCode)){
				this.formRule.verificationCode = false;
				this.$refs.verificationCode.focus();
				return false;
			}
			this.formRule.verificationCode = true;
			return true;
		},
		checkAll(){
			return this.checkEmail() && this.checkPassword() && this.checkCode();
		},
		
		doSubmit(step){
			
			if(step == 1){
				if(!this.checkCode() || !this.checkEmail()){
					return ;
				}
				this.loading = true;
				this.request.post('/api/v1/frontDeskUser/sendRetrievePasswordEmail',{
					email:this.formData.email,
					verificationCode:this.formData.verificationCode
				}).then(res=>{
					this.loading = false;
					if(res && res.status == 1){
						alert(this.L("密码已发送,请查收邮箱."));
						this.step = 2;
						this.formData.verificationCode = "";
						this.formData.password = "";
						this.formData.repassword = "";
					}
				});
				
			} else if(step == 2){
				if(!this.checkEmpty('code') || !this.checkPassword()|| !this.checkPasswordConfirm()){
					return ;
				}
				this.loading = true;
				this.request.post('/api/v1/frontDeskUser/retrievePassword',{
					code:this.formData.code,
					newPassword:this.formData.password
				}).then(res=>{
					this.loading = false;
					if(res && res.status == 1){
						alert(this.L("修改成功!"));
						this.$router.push("/User/Login")
					}
				});
			}
		}
  }
}
</script>

<style scoped lang="less">
.info{
	font-size:16px;
}
</style>